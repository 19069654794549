import RouteSetup from './router/RouteSetup';
import React, { useEffect, useState } from 'react';
import { fetchToken, onMessageListener } from './firebase';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { baseURL } from './config';


function App() {

  const [deviceToken, setDeviceToken] = useState();
  const navigate = useNavigate();



  useEffect(() => {
    fetchToken(setDeviceToken);
    let authToken = localStorage.getItem('token');
    if (authToken && deviceToken) {
      sendTokenToServer(setDeviceToken);
    }
    onMessageListener()
      .then((payload) => {
        notification.open({
          // type: 'info',
          // duration: null,
          message: payload.notification.title,
          description: payload.notification.body,
          onClick: () => navigate('/notifications'),
        });
      })
      .catch((err) => console.log('failed: ', err));
  }, [deviceToken]);

  const sendTokenToServer = async () => {
    let authToken = localStorage.getItem('token');
    const response = await fetch(`${baseURL}user/device-token`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ device_token: deviceToken })
    }).then(r => r.json())
      .then(r => console.log(r))
      .catch(e => console.log(e))

  };

  return (
    <RouteSetup />
  );
}

export default App;
